import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import {
  RefObject, useRef, createRef 
} from 'react'
import { getSectionTheme } from '@/utils/theme.utils'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import PrimaryTitle from '../../components/PrimaryTitle'
import SecondaryTitle from '../../components/SecondaryTitle'
import WrapperContentItemsContainer from '../../components/WrapperContentItemsContainer'

import useTitleHeight from '@/hooks/useTitleHeight'
import {
  Arrow,
  NextArrowContainer,
  PrevArrowContainer,
  Wrapper,
} from './SliderForCards.styles'
import { ContentItem } from '@/components'

const MIN_SLIDES = 4

const SliderForCards: React.FC<WrapperContentInterface> = ({
  primaryTitle,
  secondaryTitle,
  theme,
  itemList,
  withSeparator,
}) => {
  const itemListCollection = itemList?.filter((item: any) => item)
  const titleRefs = useRef<RefObject<HTMLElement | HTMLParagraphElement>[]>(
    Array.from({ length: itemList?.length }, () => createRef())
  )
  const height = useTitleHeight(titleRefs.current)
  const getSliderSettings = (length: number) => ({
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: length <= MIN_SLIDES ? length : MIN_SLIDES,
    variableWidth: false,
    centerMode: false,
    accessibility: false,
    swipeToSlide: true,
    nextArrow: (
      <NextArrowContainer>
        <Arrow />
      </NextArrowContainer>
    ),
    prevArrow: (
      <PrevArrowContainer>
        <Arrow />
      </PrevArrowContainer>
    ),
    responsive: [
      {
        breakpoint: 1239,
        settings: {
          variableWidth: true,
          centerMode: length < 4,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: length > 3,
          arrows: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 904,
        settings: {
          variableWidth: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: length > 1,
          arrows: false,
          swipeToSlide: true,
        },
      },
    ],
  })

  const settings = getSliderSettings(itemListCollection?.length)

  return (
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(theme) }}>
      <WrapperContentItemsContainer
        style={{ overflow: 'hidden' }}
        withSeparator={withSeparator}
      >
        {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
        <PrimaryTitle>{primaryTitle}</PrimaryTitle>
        <Wrapper length={itemList?.length || MIN_SLIDES}>
          {itemList?.length > 0 && (
            <Slider {...settings}>
              {itemList?.map((item: any, index: number) => {
                // Will need to add a wrapper for forwarding ref if ContentItem is dynamic import
                return (
                  <ContentItem
                    data={item}
                    key={item.sys?.id}
                    ref={titleRefs.current[index]}
                    height={height}
                  />
                )
              })}
            </Slider>
          )}
        </Wrapper>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default SliderForCards
